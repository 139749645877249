import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import "@hotwired/turbo-rails"
import "../channels"
import "../controllers"
import "../src"
import "../config"
import * as ActiveStorage from "@rails/activestorage"
import * as ActionCable from '@rails/actioncable'
import LocalTime from "local-time"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

Turbo.setFormMode("off")
ActionCable.logger.enabled = true
LocalTime.start()
ActiveStorage.start()

// Vite appears to bundle code in such a way that
// makes Rails UJS autostart itself. This only happens
// in production, so explicitly starting is required
// in dev envs.
if (!window._rails_loaded) {
  Rails.start()
}

// const events = [
//   "turbo:fetch-request-error",
//   "turbo:frame-missing",
//   "turbo:frame-load",
//   "turbo:frame-render",
//   "turbo:before-frame-render",
//   "turbo:load",
//   "turbo:render",
//   "turbo:before-stream-render",
//   "turbo:before-render",
//   "turbo:before-cache",
//   "turbo:submit-end",
//   "turbo:before-fetch-response",
//   "turbo:before-fetch-request",
//   "turbo:submit-start",
//   "turbo:visit",
//   "turbo:before-visit",
//   "turbo:click"
// ]

// events.forEach(e => {
//   addEventListener(e, () => {
//     console.log(e);
//     alert(e);
//   });
// });