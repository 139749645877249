import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timing"]

  connect() {
    this.updateTiming()
  }

  updateTiming() {
    const scheduleTypeSelect = document.querySelector('select[name="scheduled_task[schedule_type]"]')
    const timeSelect = document.querySelector('select[name="scheduled_task[time]"]')

    if (scheduleTypeSelect && timeSelect && scheduleTypeSelect.value && timeSelect.value) {
      const scheduleType = scheduleTypeSelect.value
      const time = timeSelect.value

      if (scheduleType === "daily") {
        this.timingTarget.value = `every day at ${time}`
      } else if (scheduleType === "weekly") {
        const today = new Date().toLocaleString('en-us', {weekday:'long'}).toLowerCase()
        this.timingTarget.value = `every ${today} at ${time}`
      }
    }
  }

  updateTimeOptions() {
    this.updateTiming()
  }
}